import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const Media2019Page = () => (
  <Layout>
    <div id="wrapper">
      <ul>
        <li><Link to="/" className="button ">Nazad</Link></li>
      </ul>

      <div className="media">
        <h1>Press clippings</h1>

        <h5 className="media__title">TV</h5>
        <ol className="media__list">
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/a/growit.tech/file/d/1F4vM8ysjjN4RWYoelHD1-G7FJOp1BiW_/view?usp=sharing" className="media__list-link">RTS</a>
          </li>
        </ol>

        <h5 className="media__title">Web</h5>
        <ol className="media__list">
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://startit.rs/igraj-za-10-humanitarni-it-turnir-u-basketu-3x3/" className="media__list-link">Startit</a>
          </li>
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://www.netokracija.rs/event/humanitarni-it-turnir-igraj-za-10" className="media__list-link">Netokracija</a>
          </li>
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://www.b92.net/sport/olimpizam/vesti.php?yyyy=2019&mm=07&dd=29&nav_id=1571635&fbclid=IwAR1l5jUXLDgyWZTGdTkuMQQPzJ3nB0fv7L4IVVpqtZbHQqcFyFlrKPgqv3Q" className="media__list-link">B92</a>
          </li>
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://sport.blic.rs/kosarka/it-firme-organizovace-humanitarni-turnir-na-adi/q261kkj" className="media__list-link">Blic</a>
          </li>
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://www.helloworld.rs/desavanja/humanitarni-it-turnir-u-basketu-33/8247" className="media__list-link">Hello World</a>
          </li>
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://casopisinterfon.rs/2019/08/09/nemanja-cedomirovic-igraj-za-10-ministarstvo-je-palo-na-ispitu-mi-ne-smemo/" className="media__list-link">InterFON</a>
          </li>
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://www.danubeogradu.rs/2019/08/igraj-za-10-humanitarni-turnir-u-basketu-na-adi-ciganliji/" className="media__list-link">Dan u Beogradu</a>
          </li>
        </ol>

        <h5 className="media__title">Radio</h5>
        <ol className="media__list">
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Beograd202/status/1165151365649358848" className="media__list-link">Radio 202</a>
          </li>
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="http://www.rts.rs/page/radio/sr/story/25/beograd-202/3629424/.html" className="media__list-link">Radio 202</a>
          </li>
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="http://www.rts.rs/page/radio/sr/story/25/beograd-202/3635375/vikend-te-zove-.html" className="media__list-link">Radio 202</a>
          </li>
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://radiolaguna.rs/2019/08/02/igraj-za-10-humanitarni-turnir-u-basketu-na-adi-ciganliji/" className="media__list-link">Radio Laguna</a>
          </li>
        </ol>

        <h5 className="media__title">Social</h5>
        <ol className="media__list">
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/p/B1ih6oKIdvA/?igshid=1uy18yeuwxo3" className="media__list-link">Beograd zivi</a>
          </li>
          <li className="media__list-item">
            <a target="_blank" rel="noopener noreferrer" href="https://photos.app.goo.gl/MT5XPTaq33gQQ1EZ7" className="media__list-link">Slike storija i najava poznatih ličnosti na ovom linku, ukupni broj pratilaca preko 500.000</a>
          </li>
        </ol>
      </div>
    </div>
  </Layout>
)

export default Media2019Page
